



















































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import { VulnListObj } from '@/views/vuln/types'
// import VulnCard from '@/views/vuln/components/vulnCard.vue'
import ScaCard from '@/views/vuln/components/ScaCard.vue'
import ScanCard from '@/views/vuln/components/ScanCard.vue'
import Sync1 from '@/views/vuln/components/sync1.vue'
import ChangeStatusPop from './components/changeStatusPop.vue'

@Component({
  name: 'VulListComponent',
  components: { ScaCard, ScanCard, ChangeStatusPop, Sync1 },
})
export default class VulListComponent extends VueBase {
  @Prop() version: number | string | undefined
  @Prop() projectId!: string | number | undefined
  private inteRules = {
    obj: [{ required: true, message: '请选择项目', trigger: 'blur' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    type: [{ required: true, message: '请选择缺陷类型', trigger: 'blur' }],
    level: [{ required: true, message: '请选择缺陷等级', trigger: 'blur' }],
  }
  private projectOtions: any = []
  private typeOptions: any = []
  private priorityOtions: any = []
  private settingInte: any = []
  private activeType: any = ''
  private pageInfo = {}
  private alltotal: any = 0

  private getItemTitle(item: any) {
    let title = ''
    title = `${item.uri}  ${item.http_method} 出现 ${item.strategy__vul_name}`
    if (item.taint_position) {
      title += ' 位置:' + item.taint_position
    }
    return title
  }

  private getLevelInfo(level_id: any) {
    switch (level_id) {
      case 1:
        return {
          className: 'height',
          levelName: '高危',
        }
      case 2:
        return {
          className: 'middle',
          levelName: '中危',
        }
      case 3:
        return {
          className: 'low',
          levelName: '高危',
        }
      case 5:
        return {
          className: 'info',
          levelName: '提示',
        }
    }
  }

  private searchOrderList: any = [
    {
      label: '默认排序',
      value: null,
    },
    {
      label: '升序',
      value: true,
    },
    {
      label: '降序',
      value: false,
    },
  ]

  private resetData() {
    // this.page = 1
    // this.tableData = []

    // this.searchObj.project_str = ''
    this.searchObj.project_str = []
    this.searchObj.order_type = ''
    this.searchObj.keywords = ''
    // this.searchObj.hook_type_str = ''
    this.searchObj.hook_type_str = []
    this.searchObj.sort = null
    this.newSelectData()
  }

  private goDetail(item: any) {
    this.$router.push(
      `/project/leakDetail/1/${item.id}?status=` +
        this.searchObj.status +
        '&id=' +
        item.id +
        '&backTitle='
    )
  }

  private chooseItems: Array<any> = []

  private handleSelectionChange(values: any) {
    console.log(values)
    this.chooseItems = values
  }

  filterHandler(value: any, row: any, column: any) {
    if (value.hasOwnProperty('scaNames')){
      this.searchObj.level_str = value.scaNames
    }
    if (value.hasOwnProperty('scaStatus')){
      this.searchObj.status_str = value.scaStatus
    }
    
    if (value.hasOwnProperty('filterScaNames')){
      this.searchObj.hook_type_str = value.filterScaNames
    }

    // if (value.scaNames && value.scaNames.length > 0) {
    //   this.searchObj.level_str = value.scaNames
    // } else {
    //   this.searchObj.level_str = []
    // }

    // if (value.scaStatus && value.scaStatus.length > 0) {
    //   this.searchObj.status_str = value.scaStatus
    // } else {
    //   this.searchObj.status_str = []
    // }

    // if (value.filterScaNames && value.filterScaNames.length > 0) {
    //   this.searchObj.hook_type_str = value.filterScaNames
    // } else {
    //   this.searchObj.hook_type_str = []
    // }
    this.newSelectData()
  }

  private searchHookTypeTableData(item: any) {
    this.searchOptionsObj.hook_type.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.hook_type_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.hook_type_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.hook_type_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.hook_type_str = result
        }
      }
    })
    this.newSelectData()
  }
  private searchTableData(item: any) {
    this.searchOptionsObj.level.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.level_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.level_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.level_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.level_str = result
        }
      }
    })
    // this.getTableData(true)
    this.newSelectData()
  }

  private searchStatusTableData(item: any) {
    this.searchOptionsObj.status.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.status_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.status_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.status_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.status_str = result
        }
      }
    })
    this.newSelectData()
  }

  private vulnType = 'vuln'
  changeVulnType(type: any) {
    this.reset()
    this.vulnSummary()
    this.summary()
  }
  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }
  handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private showStatusPop: boolean = false
  private ids: any = ''
  statusPop() {
    if (this.chooseItems.length <= 0) {
      this.$message.warning('请选择需要更改状态的漏洞')
      return
    }

    const ids = this.chooseItems
      .map((item: any) => {
        return item.id
      })
      .filter((item1: any) => item1)

    this.ids = ids

    // if (!this.tableData.some((item: any) => item.checked)) {
    //   this.$message.warning('请选择需要变更状态的漏洞')
    //   return
    // }
    // this.ids = this.tableData
    //   .map((item) => {
    //     if (item.checked) {
    //       return item.id
    //     }
    //   })
    //   .filter((item) => item)
    this.showStatusPop = true
  }

  private async summary() {
    const res = await this.services.vuln.summary()
    if (res.status === 201) {
      this.settingInte = res.data
    }
  }

  private page = 1
  private pageSize = 20
  private dataEnd = false
  private tableData: Array<any> = []
  private searchOptionsObj: any = {
    filterHookTypes: [],
    filterStatus: [],
    filterLevel: [
      {
        text: '高危',
        value: 1,
      },
      {
        text: '中危',
        value: 2,
      },
      {
        text: '低危',
        value: 3,
      },
      {
        text: '无风险',
        value: 4,
      },
      {
        text: '提示',
        value: 5,
      },
    ],
    level: [
      {
        name: '高危',
        num: '0',
        id: 1,
      },
      {
        name: '中危',
        num: '0',
        id: 2,
      },
      {
        name: '低危',
        num: '0',
        id: 3,
      },
      {
        name: '无风险',
        num: '0',
        id: 4,
      },
      {
        name: '提示',
        num: '0',
        id: 5,
      },
    ],
    status: [
      {
        name: '待验证',
        num: 0,
        id: 1,
      },
      {
        name: '验证中',
        num: 0,
        id: 2,
      },
      {
        name: '已确认',
        num: 0,
        id: 3,
      },
      {
        name: '已忽略',
        num: 0,
        id: 4,
      },
      {
        name: '已处理',
        num: 0,
        id: 5,
      },
    ],
    availability: [],
    hook_type: [],
    orderOptions: [
      {
        label: '漏洞级别',
        value: 1,
      },
      {
        label: '发现时间',
        value: 2,
      },
      {
        label: '最新活跃',
        value: 3,
      },
      {
        label: '状态',
        value: 4,
      },
    ],
    statusOptions: [],
  }

  private reset() {
    this.searchObj = {
      level_str: [],
      project_str: [],
      order_type: '',
      keywords: '',
      status_str: [],
      source_type_str: [],
      availability_str: [],
      hook_type_str: [],
      language_str: [],
      sort: null,
    }
    this.newSelectData()
  }

  private searchObj: any = {
    level_str: [],
    project_str: [],
    order_type: '',
    keywords: '',
    status_str: [],
    source_type_str: [],
    availability_str: [],
    hook_type_str: [],
    language_str: [],
    sort: null,
  }

  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.searchOptionsObj.filterStatus = res.data.map((item:any) => {
      return {
        value: item.id,
        text: item.name,
      }
    })
  }

  created() {
    this.getTableData()
    this.vulnSummary()
    this.summary()
    this.getStatus()
  }

  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }

  private deleteVul(type: string) {
    // if (!this.tableData.some((item: any) => item.checked)) {
    //   this.$message.warning('请选择需要删除的漏洞')
    //   return
    // }

    if (this.chooseItems.length <= 0) {
      this.$message.warning('请选择需要删除的漏洞')
      return
    }

    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除所选应用漏洞?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      let res: any = {}
      // const ids = this.tableData
      //   .map((item) => {
      //     if (item.checked) {
      //       return item.id
      //     }
      //   })
      //   .filter((item) => item)
      const ids = this.chooseItems
        .map((item: any) => {
          return item.id
        })
        .filter((item1: any) => item1)

      res = await this.services.vuln.vulListDelete({
        source_type: this.vulnType === 'vuln' ? 1 : 2,
        ids: String(ids),
      })

      if (res.status !== 201) {
        this.$message.error('漏洞删除失败，请再次尝试')
      } else {
        this.$message.success('漏洞删除成功')
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private selectAll(e: any) {
    const flag =
      this.tableData.length > 0 && this.tableData.every((item) => item.checked)
    if (flag) {
      this.tableData.forEach((item) => this.$set(item, 'checked', false))
    } else {
      this.tableData.forEach((item) => this.$set(item, 'checked', true))
    }
    console.log(e)
  }

  private newSelectData() {
    this.page = 1
    this.dataEnd = false
    this.tableData = []

    this.getTableData()
  }

  private async getTableData(reflash = false) {
    let sort = undefined
    if (this.searchObj.sort === true) {
      sort = 1
    }
    if (this.searchObj.sort === false) {
      sort = 0
    }
    let params
    if (this.vulnType === 'scan') {
      params = {
        page: this.page,
        page_size: this.pageSize,
        bind_project_id: Number(this.projectId),
        project_version_id: Number(this.version),
        keywords: this.searchObj.keywords || undefined,
        project_id: this.searchObj.project_str.length
          ? this.searchObj.project_str
          : undefined,
        vul_level_id: this.searchObj.level_str.length
          ? this.searchObj.level_str
          : undefined,
        vul_type: this.searchObj.hook_type_str.length
          ? this.searchObj.hook_type_str
          : undefined,
        order_type_desc: sort,
      }
    } else {
      params = {
        page: this.page,
        page_size: this.pageSize,
        bind_project_id: this.projectId,
        project_version_id: String(this.version),
        level_id_str: this.searchObj.level_str.join(',') || undefined,
        project_id_str: this.searchObj.project_str.join(',') || undefined,
        // project_id_str: this.searchObj.project_str|| undefined,
        order_type: this.searchObj.order_type || undefined,
        keywords: this.searchObj.keywords || undefined,
        status_id_str: this.searchObj.status_str.join(',') || undefined,
        source_type_str: this.searchObj.source_type_str.join(',') || undefined,
        availability_str:
          this.searchObj.availability_str.join(',') || undefined,
        hook_type_id_str: this.searchObj.hook_type_str.join(',') || undefined,
        language_str: this.searchObj.language_str.join(',') || undefined,
        order_type_desc: sort,
        type: this.vulnType,
      }
    }
    this.loadingStart()
    const { status, data, msg, page } = await this.services.vuln.vulListContent(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const tableData = (data.messages || data).reduce(
      (list: Array<any>, item: any) => {
        let vul_number = ''
        if (item.vul_cve_nums) {
          vul_number = `${item.vul_cve_nums.cnnvd} | ${item.vul_cve_nums.cnvd} | ${item.vul_cve_nums.cve} | ${item.vul_cve_nums.cwe}`
        }
        list.push({
          ...item,
          first_time: formatTimestamp(item.first_time),
          latest_time: getPassedTime(item.latest_time),
          vul_number: vul_number,
          id: item.id,
          vul_name_title: item.strategy__vul_name,
          vul_name_info: `${item.taint_position} ${item.uri}`,
          latest_time_nyr: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )

    this.alltotal = data.page.alltotal || 0
    // if (tableData.length < this.pageSize) {
    //   this.dataEnd = true
    //   this.alltotal = this.page * this.pageSize - 1
    // } else {
    //   this.dataEnd = false
    //   this.alltotal = this.page * this.pageSize + 1
    // }
    // if (reflash) {
    this.tableData = []
    // }
    this.pageInfo = page
    this.tableData = [...this.tableData, ...tableData]
  }

  private SummaryCatch: any = {}
  private boxHeight: any = ''
  private showAccor: boolean = false
  private async vulnSummary() {
    this.searchOptionsObj.level = []
    this.searchOptionsObj.availability = []
    this.searchOptionsObj.hook_type = []
    this.searchOptionsObj.status = []
    this.searchOptionsObj.projects = []

    if (this.vulnType === 'vuln') {
      this.searchOptionsObj.orderOptions = [
        {
          label: '漏洞级别',
          value: 1,
        },
        {
          label: '发现时间',
          value: 2,
        },
        {
          label: '最新活跃',
          value: 3,
        },
        {
          label: '状态',
          value: 4,
        },
      ]
    } else {
      this.searchOptionsObj.orderOptions = [
        {
          label: '漏洞级别',
          value: 1,
        },
        // {
        //  label: '发现时间',
        //  value: 2,
        // },
        {
          label: '最新活跃',
          value: 3,
        },
      ]
    }

    let params
    params = {
      bind_project_id: this.projectId,
      project_version_id: String(this.version),
      type: this.vulnType,
    }
    if (this.vulnType === 'scan') {
      params = {
        bind_project_id: Number(this.projectId),
        project_version_id: Number(this.version),
        type: this.vulnType,
      }
    }
    this.loadingStart()
    let res
    if (this.SummaryCatch[this.vulnType + 'version:' + this.version]) {
      res = this.SummaryCatch[this.vulnType + 'version:' + this.version]
    } else {
      res = await this.services.vuln.vulSummaryNum(params)
    }
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    if (this.vulnType === 'scan') {
      this.SummaryCatch[this.vulnType] = res
      this.searchOptionsObj.projects =
        res.data.project_info && res.data.project_info.length
          ? res.data.project_info
          : this.searchOptionsObj.projects
      this.searchOptionsObj.hook_type =
        res.data.vul_type && res.data.vul_type.length
          ? res.data.vul_type
          : this.searchOptionsObj.hook_type
      this.searchOptionsObj.level =
        res.data.vul_level && res.data.vul_level.length
          ? res.data.vul_level
          : [
              {
                name: '高危',
                num: 0,
                id: 1,
              },
              {
                name: '中危',
                num: 0,
                id: 2,
              },
              {
                name: '低危',
                num: 0,
                id: 3,
              },
              {
                name: '无风险',
                num: 0,
                id: 4,
              },
              {
                name: '提示',
                num: 0,
                id: 5,
              },
            ]
    } else {
      this.SummaryCatch[this.vulnType] = res

      const availability = Object.values(res.data.messages.availability || {})
      this.searchOptionsObj.level =
        res.data.messages.level && res.data.messages.level.length
          ? res.data.messages.level
          : [
              {
                name: '高危',
                num: '0',
                id: 1,
              },
              {
                name: '中危',
                num: '0',
                id: 2,
              },
              {
                name: '低危',
                num: '0',
                id: 3,
              },
              {
                name: '无风险',
                num: '0',
                id: 4,
              },
              {
                name: '提示',
                num: '0',
                id: 5,
              },
            ]
      this.searchOptionsObj.status =
        res.data.messages.status && res.data.messages.status.length
          ? res.data.messages.status
          : [
              {
                name: '待验证',
                num: '0',
                id: 1,
              },
              {
                name: '验证中',
                num: '0',
                id: 2,
              },
              {
                name: '已确认',
                num: '0',
                id: 3,
              },
              {
                name: '已忽略',
                num: '0',
                id: 4,
              },
              {
                name: '已处理',
                num: '0',
                id: 5,
              },
            ]

      this.searchOptionsObj.availability =
        availability && availability.length
          ? availability
          : this.searchOptionsObj.availability
      this.searchOptionsObj.hook_type =
        res.data.messages.hook_type && res.data.messages.hook_type.length
          ? res.data.messages.hook_type
          : this.searchOptionsObj.hook_type
      this.searchOptionsObj.projects =
        res.data.messages.project && res.data.messages.project.length
          ? res.data.messages.project
          : this.searchOptionsObj.projects
      // todo 修改点
      const hookTypes =
        res.data.messages.hook_type && res.data.messages.hook_type.length
          ? res.data.messages.hook_type
          : this.searchOptionsObj.hook_type
      const filterHookTypes: any = []

      hookTypes.map((item: any) => {
        filterHookTypes.push({
          text: item.name,
          value: item.id,
        })
      })
      this.searchOptionsObj.filterHookTypes = filterHookTypes
    }
    this.$nextTick(() => {
      if ((this.$refs.accordion as any)?.offsetHeight > 140) {
        this.boxHeight = '140px'
        this.showAccor = true
      } else {
        this.boxHeight = 'auto'
        this.showAccor = false
      }
      console.log(
        'this.$refs.accordion',
        (this.$refs.accordion as any)?.offsetHeight
      )
    })
  }
  updated() {
    console.log('-------updated------')
  }
  private isExpand: boolean = false
  handleAccor() {
    this.isExpand = !this.isExpand
    this.boxHeight = this.isExpand ? 'auto' : '140px'
  }
}
