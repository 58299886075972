

































import { Component, Emit, Prop, PropSync } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'ChangeStatusPop' })
export default class ChangeStatusPop extends VueBase {
  @PropSync("show", { required: true }) showProp: any
  @Prop({default: ''}) ids?: any
  @Prop({default: ''}) id?: any
  @Emit('callback')
  callback(val: any) {
    return val
  }


	get dialogVisible() {
		if (this.showProp) {
			this.init()
		}
		return this.showProp
	}
	set dialogVisible(val: any) {
		this.showProp = val
	}

  init() {
    this.getStatus()
  }

  private statusOptions:any = {}
  private statusForm: any = {
    status_id: 1,
    addtional_msg: ''
  }
  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.statusOptions = res.data.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  async changeStatus() {
    const res = await this.services.vuln.changeStatus({
      vul_ids: this.ids || undefined,
      vul_id: this.id || undefined,
      ...this.statusForm
    })

    if (res.status === 201) {
      this.$message.success('漏洞状态变更成功')
      let label = this.statusOptions.find((item: any) => item.value == this.statusForm.status_id)?.label
      this.callback(label)
      this.handleClose()
      return
    }
    this.$message.error(res.msg)
  }

  handleClose() {
    this.statusForm = {
      status_id: 1,
      addtional_msg: ''
    }
    this.dialogVisible = false
  }
}
